import { useState, useEffect } from 'react';
import { apiService } from '../services/api';
import { getImageUrl } from '../utils/helpers';

export default function About() {
    const [about, setAbout] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAbout = async () => {
            try {
                const response = await apiService.getAbout();
                setAbout(response.data);
            } catch (err) {
                setError("Ma'lumotlarni yuklashda xatolik yuz berdi");
                console.error("Ma'lumotlarni yuklashda xatolik:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchAbout();
    }, []);

    if (loading) {
        return (
            <div className="pt-20 px-4 min-h-screen bg-gradient-to-b from-blue-50 to-white">
                <div className="container mx-auto text-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-200 border-t-blue-600 mx-auto"></div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="pt-20 px-4 min-h-screen bg-gradient-to-b from-blue-50 to-white">
                <div className="container mx-auto">
                    <div className="bg-red-100 text-red-700 p-6 rounded-xl shadow-md text-center max-w-2xl mx-auto">
                        <svg className="w-12 h-12 text-red-500 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="pt-20 min-h-screen bg-gradient-to-b from-blue-50 to-white">
            {about && (
                <div className="container mx-auto px-4">
                    {/* Hero Section */}
                    <div className="max-w-6xl mx-auto mb-16">
                        <div className="bg-white rounded-3xl shadow-xl overflow-hidden transform hover:scale-[1.02] transition-all duration-300">
                            <div className="md:flex items-stretch">
                                <div className="md:w-1/2 relative group">
                                    <div className="absolute inset-0 bg-gradient-to-r from-blue-600/20 to-purple-600/20 group-hover:opacity-75 transition-opacity duration-300"></div>
                                    {about.image && (
                                        <img 
                                            src={getImageUrl(about.image)} 
                                            alt={about.title}
                                            className="w-full h-[500px] object-cover transform group-hover:scale-105 transition-transform duration-500"
                                        />
                                    )}
                                </div>
                                <div className="md:w-1/2 p-8 md:p-12 flex flex-col justify-center">
                                    <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                                        {about.title}
                                    </h1>
                                    <p className="text-gray-600 text-lg leading-relaxed">
                                        {about.content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Stats Section */}
                    <div className="max-w-6xl mx-auto mb-16 grid grid-cols-2 md:grid-cols-4 gap-6">
                        {[
                            { number: "15+", label: "Yillik tajriba", icon: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" },
                            { number: "1000+", label: "Muvaffaqiyatli operatsiyalar", icon: "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" },
                            { number: "50+", label: "Ilmiy nashrlar", icon: "M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" },
                            { number: "5000+", label: "Mamnun bemorlar", icon: "M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" }
                        ].map((stat, index) => (
                            <div key={index} className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4 mx-auto">
                                    <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={stat.icon} />
                                    </svg>
                                </div>
                                <div className="text-center">
                                    <div className="text-2xl font-bold text-blue-600 mb-1">{stat.number}</div>
                                    <div className="text-gray-600 text-sm">{stat.label}</div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Education & Experience Section */}
                    <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8 mb-16">
                        {/* Education */}
                        {about.education && (
                            <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
                                <div className="flex items-center mb-8">
                                    <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                                        <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l9-5-9-5-9 5 9 5z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l9-5-9-5-9 5 9 5z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l9-5-9-5-9 5 9 5z" />
                                        </svg>
                                    </div>
                                    <h2 className="text-3xl font-bold text-gray-800">Ta'lim</h2>
                                </div>
                                <div className="space-y-6">
                                    {about.education.split('\n').filter(Boolean).map((edu, index) => (
                                        <div key={index} className="relative pl-8 border-l-2 border-blue-200">
                                            <div className="absolute left-[-5px] top-0 w-2 h-2 bg-blue-600 rounded-full"></div>
                                            <p className="text-gray-600">{edu}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Experience */}
                        {about.experience && (
                            <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
                                <div className="flex items-center mb-8">
                                    <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                                        <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                        </svg>
                                    </div>
                                    <h2 className="text-3xl font-bold text-gray-800">Tajriba</h2>
                                </div>
                                <div className="space-y-6">
                                    {about.experience.split('\n').filter(Boolean).map((exp, index) => (
                                        <div key={index} className="relative pl-8 border-l-2 border-purple-200">
                                            <div className="absolute left-[-5px] top-0 w-2 h-2 bg-purple-600 rounded-full"></div>
                                            <p className="text-gray-600">{exp}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
