import { useState } from 'react';
import { apiService } from '../services/api';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await apiService.createContact(formData);
      setSuccess(true);
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (err) {
      setError('Xabaringizni yuborishda xatolik yuz berdi. Iltimos, qayta urinib koʻring.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pt-20 px-4">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-8">Aloqa</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
          {/* İletişim Bilgileri */}
          <div className="order-2 md:order-1">
            <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold mb-6">Aloqa uchun ma'lumotlar</h2>
              
              <div className="space-y-4">
                <div className="flex items-center">
                  <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                    <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                    </svg>
                  </div>
                  <div>
                    <h3 className="font-semibold">Manzil</h3>
                    <p className="text-gray-600">Qo'qon shahar, Farg'ona viloyati, O'zbekiston</p>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                    <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                    </svg>
                  </div>
                  <div>
                    <h3 className="font-semibold">Telefon</h3>
                    <p className="text-gray-600">+998 (XX) XXX XX XX</p>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                    <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                    </svg>
                  </div>
                  <div>
                    <h3 className="font-semibold">E-pochta</h3>
                    <p className="text-gray-600">info@dralivaliyev.com</p>
                  </div>
                </div>
              </div>

              {/* Çalışma Saatleri */}
              <div className="mt-8">
                <h3 className="text-xl font-semibold mb-4">Ish vaqti</h3>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex justify-between">
                    <span>Dushanba - Juma:</span>
                    <span>09:00 - 18:00</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Shanba:</span>
                    <span>09:00 - 13:00</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Yakshanba:</span>
                    <span>Yopiq</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Harita */}
            <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Lokatsiya</h3>
              <div className="aspect-w-16 aspect-h-9">
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48517.34949378061!2d70.88992524477788!3d40.534201917554356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38baeeeb2e1c3e7f%3A0x816d723cc5842908!2sKokand%2C%20Fergana%20ili%2C%20%C3%96zbekistan!5e0!3m2!1str!2str!4v1732833807068!5m2!1str!2str"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="rounded-lg w-full h-full"
                  title="Klinik Konumu"
                ></iframe>
              </div>
            </div>
          </div>

          {/* İletişim Formu */}
          <div className="order-1 md:order-2">
            <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold mb-6">Xabar yuborish</h2>

              {success && (
                <div className="mb-4 p-4 bg-green-100 text-green-700 rounded">
                  Xabaringiz muvaffaqiyatli yuborildi. Biz sizga imkon qadar tezroq javob beramiz.
                </div>
              )}
              
              {error && (
                <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
                  {error}
                </div>
              )}

              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-gray-700 mb-2">Isim Familiya</label>
                    <input
                      type="text"
                      className="w-full p-3 border rounded"
                      value={formData.name}
                      onChange={(e) => setFormData({...formData, name: e.target.value})}
                      required
                    />
                  </div>
                  
                  <div>
                    <label className="block text-gray-700 mb-2">E-pochta</label>
                    <input
                      type="email"
                      className="w-full p-3 border rounded"
                      value={formData.email}
                      onChange={(e) => setFormData({...formData, email: e.target.value})}
                      required
                    />
                  </div>
                  
                  <div>
                    <label className="block text-gray-700 mb-2">Mavzu</label>
                    <input
                      type="text"
                      className="w-full p-3 border rounded"
                      value={formData.subject}
                      onChange={(e) => setFormData({...formData, subject: e.target.value})}
                      required
                    />
                  </div>
                  
                  <div>
                    <label className="block text-gray-700 mb-2">Xabaringiz</label>
                    <textarea
                      className="w-full p-3 border rounded"
                      rows={4}
                      value={formData.message}
                      onChange={(e) => setFormData({...formData, message: e.target.value})}
                      required
                    ></textarea>
                  </div>
                </div>
                
                <button
                  type="submit"
                  disabled={loading}
                  className={`mt-4 bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition-colors ${
                    loading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {loading ? 'Yuborilmoqda...' : 'Yuborish'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
