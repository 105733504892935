import { Helmet } from 'react-helmet-async';

export default function SEO({ 
  title = '', 
  description = '', 
  keywords = [],
  image = ''
}) {
  const siteTitle = 'Dr. Ali Valiyev - Yurak jarrohi';
  const fullTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  const keywordsArray = Array.isArray(keywords) ? keywords : [];

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      {keywordsArray.length > 0 && (
        <meta name="keywords" content={keywordsArray.join(', ')} />
      )}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
    </Helmet>
  );
}
