import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { apiService } from '../services/api';
import { getImageUrl } from '../utils/helpers';
import SEO from '../components/SEO';

export default function ArticleDetail() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [articleResponse, allArticlesResponse] = await Promise.all([
          apiService.getArticle(id),
          apiService.getArticles()
        ]);
        
        setArticle(articleResponse.data);
        
        const related = allArticlesResponse.data
          .filter(a => a.id !== articleResponse.data.id && a.category === articleResponse.data.category)
          .slice(0, 3);
        setRelatedArticles(related);
      } catch (err) {
        setError('Maqolani yuklashda xatolik yuz berdi.');
        console.error('Maqolani yuklashda xatolik yuz berdi:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, [id]);

  if (loading) {
    return (
      <div className="pt-20 px-4 min-h-screen">
        <div className="container mx-auto text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        </div>
      </div>
    );
  }

  if (error || !article) {
    return (
      <div className="pt-20 px-4 min-h-screen">
        <div className="container mx-auto">
          <div className="bg-red-100 text-red-700 p-4 rounded mb-6">
            {error || 'Maqola topilmadi.'}
          </div>
          <Link to="/articles" className="text-blue-600 hover:text-blue-800">
            ← Maqolalar sahifasiga qaytish
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title={article.title}
        description={article.summary || article.content.slice(0, 160)}
        keywords={article.tags ? [...article.tags, 'maqola', 'salomatlik', 'tibbiyot', 'yurak', 'jarroh'] : ['maqola', 'salomatlik', 'tibbiyot', 'yurak', 'jarroh']}
      />
      <div className="pt-20 px-4 bg-gray-50 min-h-screen">
        <div className="container mx-auto max-w-4xl">
          <nav className="flex items-center space-x-2 mb-6 text-sm">
            <Link to="/" className="text-gray-500 hover:text-blue-600">Bosh sahifa</Link>
            <span className="text-gray-500">/</span>
            <Link to="/articles" className="text-gray-500 hover:text-blue-600">Maqolalar</Link>
            <span className="text-gray-500">/</span>
            <span className="text-blue-600">{article.title}</span>
          </nav>
          
          <article className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
            <div className="relative h-96">
              <img 
                src={getImageUrl(article.image)}
                alt={article.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
              <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
                <h1 className="text-4xl font-bold mb-4">{article.title}</h1>
                <div className="flex items-center space-x-4">
                  <div className="flex items-center">
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <span>{new Date(article.created_at).toLocaleDateString('uz-UZ')}</span>
                  </div>
                  {article.category && (
                    <div className="flex items-center">
                      <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                      </svg>
                      <span>{article.category}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            <div className="p-8">
              <div className="prose max-w-none">
                {article.content.split('\n').map((paragraph, index) => (
                  <p key={index} className="text-gray-700 leading-relaxed mb-6">{paragraph}</p>
                ))}
              </div>
            </div>
          </article>

          {relatedArticles.length > 0 && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-6">Tegishli Maqolalar</h2>
              <div className="grid md:grid-cols-3 gap-6">
                {relatedArticles.map((related) => (
                  <Link 
                    key={related.id} 
                    to={`/articles/${related.id}`}
                    className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                  >
                    <img 
                      src={getImageUrl(related.image)}
                      alt={related.title}
                      className="w-full h-48 object-cover"
                    />
                    <div className="p-4">
                      <h3 className="font-semibold mb-2">{related.title}</h3>
                      <span className="text-sm text-gray-500">
                        {new Date(related.created_at).toLocaleDateString('uz-UZ')}
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}

          <div className="text-center mb-8">
            <Link 
              to="/articles" 
              className="inline-flex items-center space-x-2 bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              <span>Barcha maqolalarga qaytish</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
