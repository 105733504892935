import { useState } from 'react';
import { Link } from "react-router-dom";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
      <nav className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="text-xl font-bold text-blue-600">
            Dr. Ali Valiyev
          </Link>
          
          <div className="hidden md:flex space-x-6">
            <Link to="/" className="text-gray-600 hover:text-blue-600 transition-colors">
              Bosh sahifa
            </Link>
            <Link to="/about" className="text-gray-600 hover:text-blue-600 transition-colors">
              Haqimda
            </Link>
            <Link to="/articles" className="text-gray-600 hover:text-blue-600 transition-colors">
              Maqolalar
            </Link>
            <Link to="/completed-works" className="text-gray-600 hover:text-blue-600 transition-colors">
              Tadqiqotlarim
            </Link>
            <Link to="/qa" className="text-gray-600 hover:text-blue-600 transition-colors">
              Savol & Javob
            </Link>
            <Link to="/testimonials" className="text-gray-600 hover:text-blue-600 transition-colors">
              Bemorlarning fikrlari
            </Link>
            <Link to="/contact" className="text-gray-600 hover:text-blue-600 transition-colors">
              Aloqa
            </Link>
          </div>
          
          {/* Mobile menu button */}
          <button className="md:hidden" onClick={toggleMenu}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {isMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
        <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link to="/" className="block px-3 py-2 text-gray-600 hover:text-blue-600 transition-colors">
              Bosh sahifa
            </Link>
            <Link to="/about" className="block px-3 py-2 text-gray-600 hover:text-blue-600 transition-colors">
              Haqimda
            </Link>
            <Link to="/articles" className="block px-3 py-2 text-gray-600 hover:text-blue-600 transition-colors">
              Maqolalar
            </Link>
            <Link to="/completed-works" className="block px-3 py-2 text-gray-600 hover:text-blue-600 transition-colors">
            Tadqiqotlarim
            </Link>
            <Link to="/qa" className="block px-3 py-2 text-gray-600 hover:text-blue-600 transition-colors">
              Savol & Javob
            </Link>
            <Link to="/testimonials" className="block px-3 py-2 text-gray-600 hover:text-blue-600 transition-colors">
              Bemorlarning fikrlari
            </Link>
            <Link to="/contact" className="block px-3 py-2 text-gray-600 hover:text-blue-600 transition-colors">
              Aloqa
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
}
