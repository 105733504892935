import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">Dr. Ali Valiyev</h3>
            <p className="text-gray-400">
              Mutaxassis shifokor xizmatlari va bemorlar haqida ma'lumot platformasi
            </p>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4">Tez Havolalar</h4>
            <div className="space-y-2">
              <Link to="/" className="block text-gray-400 hover:text-white">
                Bosh sahifa
              </Link>
              <Link to="/articles" className="block text-gray-400 hover:text-white">
                Maqolalar
              </Link>
              <Link to="/testimonials" className="block text-gray-400 hover:text-white">
                Bemor Izohlari
              </Link>
              <Link to="/qa" className="block text-gray-400 hover:text-white">
                Savol & Javob
              </Link>
            </div>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4">Aloqa</h4>
            <div className="space-y-2 text-gray-400">
              <p>Email: info@dralivaliyev.com</p>
              <p>Tel: +998 (xx) xxx xx xx</p>
              <p>Manzil: Qo'qon, O'zbekiston</p>
            </div>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Abdulloh Bahromov. Barcha huquqlar himoyalangan.</p>
        </div>
      </div>
    </footer>
  );
}
  