import { useState, useEffect } from 'react';
import { apiService } from '../services/api';
import { Link } from 'react-router-dom';
import LazyImage from '../components/LazyImage';

export const getImageUrl = (imageUrl) => {
  if (!imageUrl) return '/article-placeholder.jpg';
  if (imageUrl.startsWith('http')) return imageUrl;
  return `http://localhost:8000/media/${imageUrl.replace(/^\/media\//, '')}`;
};

export default function Articles() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await apiService.getArticles();
        setArticles(response.data);
      } catch (err) {
        setError('Makaleler yüklenirken bir hata oluştu.');
        console.error('Makaleler yüklenirken hata:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchArticles();
  }, []);

  if (loading) {
    return (
      <div className="pt-20 px-4">
        <div className="container mx-auto text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-20 px-4">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-8">Maqolalar</h1>
        
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded mb-6">
            {error}
          </div>
        )}

        <div className="grid md:grid-cols-3 gap-6">
          {articles.map((article) => (
            <div key={article.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <LazyImage
                src={article.image}
                alt={article.title}
                className="w-full h-48 object-cover rounded-lg"
                placeholder="/article-placeholder.jpg"
              />
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2">{article.title}</h2>
                <p className="text-gray-600 mb-4">
                  {article.summary || article.content.slice(0, 150) + '...'}
                </p>
                <div className="flex justify-between items-center">
                  <Link 
                    to={`/articles/${article.id}`} 
                    className="text-blue-600 font-semibold hover:text-blue-800"
                  >
                    Batafsil →
                  </Link>
                  <span className="text-sm text-gray-500">
                    {new Date(article.created_at).toLocaleDateString('tr-TR')}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {articles.length === 0 && !error && (
          <div className="text-center text-gray-500 py-8">
            Hozircha maqolalar yo‘q.
          </div>
        )}
      </div>
    </div>
  );
}
  