import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { apiService } from '../services/api';
import { getImageUrl } from '../utils/helpers';

export default function CompletedWorkDetail() {
  const { id } = useParams();
  const [work, setWork] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWork = async () => {
      try {
        const response = await apiService.getResearchById(id);
        setWork(response.data);
      } catch (err) {
        setError('Tadqiqot tafsilotlarini yuklashda xatolik yuz berdi.');
        console.error('Tadqiqot tafsilotlarini yuklashda xatolik yuz berdi:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchWork();
  }, [id]);

  if (loading) {
    return (
      <div className="pt-20 px-4 min-h-screen bg-gray-50">
        <div className="container mx-auto text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        </div>
      </div>
    );
  }

  if (error || !work) {
    return (
      <div className="pt-20 px-4 min-h-screen bg-gray-50">
        <div className="container mx-auto">
          <div className="bg-red-100 text-red-700 p-4 rounded-lg text-center">
            {error || 'Hech qanday tadqiqot topilmadi.'}
          </div>
          <div className="text-center mt-8">
            <Link 
              to="/completed-works"
              className="inline-flex items-center text-blue-600 hover:text-blue-800"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Barcha tadqiqotlar sahifasiga qaytish
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-20 px-4 min-h-screen bg-gray-50">
      <div className="container mx-auto">
        <div className="max-w-4xl mx-auto">
          {/* Breadcrumb */}
          <div className="mb-8">
            <Link 
              to="/completed-works"
              className="text-blue-600 hover:text-blue-800 flex items-center"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
                Barcha tadqiqotlar sahifasiga qaytish
            </Link>
          </div>

          {/* Image */}
          <div className="rounded-xl overflow-hidden shadow-lg mb-8">
            <img 
              src={getImageUrl(work.image)}
              alt={work.title}
              className="w-full h-96 object-cover"
            />
          </div>

          {/* Content */}
          <div className="bg-white rounded-xl shadow-lg p-8">
            <h1 className="text-3xl font-bold mb-6">{work.title}</h1>
            
            <div className="grid md:grid-cols-2 gap-6 mb-8">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-semibold mb-2">Kategoriya</h3>
                <p className="text-gray-600">{work.category}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-semibold mb-2">Sana</h3>
                <p className="text-gray-600">
                  {new Date(work.date).toLocaleDateString('uz-UZ')}
                </p>
              </div>
              {work.success_rate && (
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-semibold mb-2">Muvaffaqiyat</h3>
                  <p className="text-gray-600">%{work.success_rate}</p>
                </div>
              )}
              {work.patient_count && (
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-semibold mb-2">Bemorlar soni</h3>
                  <p className="text-gray-600">{work.patient_count} Hasta</p>
                </div>
              )}
            </div>

            <div className="prose max-w-none">
              <h2 className="text-xl font-semibold mb-4">Batafsil ma'lumot</h2>
              <p className="text-gray-600 whitespace-pre-line">{work.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
