import { useState, useEffect } from 'react';
import { apiService } from '../services/api';
import { getImageUrl } from '../utils/helpers';
import { Link } from 'react-router-dom';

export default function CompletedWorks() {
  const [works, setWorks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = [
    { id: 'all', label: 'Hammasi' },
    { id: 'OPERATION', label: 'Operatsiya' },
    { id: 'PUBLICATION', label: 'Ilmiy Nashr' },
    { id: 'RESEARCH', label: 'Ilmiy Tadqiqot' },
    { id: 'CONFERENCE', label: 'Konferensiya' },
    { id: 'TRAINING', label: 'Malaka Oshirish' },
    { id: 'CERTIFICATE', label: 'Sertifikat' },
    { id: 'AWARD', label: 'Mukofot' },
    { id: 'OTHER', label: 'Boshqa' }
  ];

  useEffect(() => {
    const fetchWorks = async () => {
      try {
        const response = await apiService.getResearch();
        setWorks(response.data);
      } catch (err) {
        setError('Tadqiqotlarni yuklashda xatolik yuz berdi.');
        console.error('Tadqiqotlarni yuklashda xatolik yuz berdi:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchWorks();
  }, []);

  const filteredWorks = selectedCategory === 'all' 
    ? works 
    : works.filter(work => work.category === selectedCategory);

  const handleCategoryChange = async (categoryId) => {
    setSelectedCategory(categoryId);
    setLoading(true);
    try {
      const response = categoryId === 'all' 
        ? await apiService.getResearch()
        : await apiService.getResearchByCategory(categoryId);
      setWorks(response.data);
    } catch (err) {
      setError('Tadqiqotlarni yuklashda xatolik yuz berdi.');
      console.error('Tadqiqotlarni yuklashda xatolik yuz berdi:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="pt-20 px-4 min-h-screen bg-gray-50">
        <div className="container mx-auto text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-20 px-4 min-h-screen bg-gray-50">
      <div className="container mx-auto">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Tugallangan Tadqiqotlar</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
          Ko'p yillik tajriba va muvaffaqiyatli natijalar bilan amalga oshirgan ishimizni ko'rib chiqishingiz mumkin.
          </p>
        </div>

        {/* Category Filters */}
        <div className="flex flex-wrap justify-center gap-4 mb-12">
          {categories.map(category => (
            <button
              key={category.id}
              onClick={() => handleCategoryChange(category.id)}
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                selectedCategory === category.id
                  ? 'bg-blue-600 text-white shadow-lg'
                  : 'bg-white text-gray-600 hover:bg-blue-50'
              }`}
            >
              {category.label}
            </button>
          ))}
        </div>

        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-lg mb-6 text-center">
            {error}
          </div>
        )}

        {/* Works Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredWorks.map((work) => (
            <Link 
              key={work.id}
              to={`/completed-works/${work.id}`}
              className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300 group"
            >
              <div className="relative h-56">
                <img 
                  src={getImageUrl(work.image)}
                  alt={work.title}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-3 group-hover:text-blue-600 transition-colors">
                  {work.title}
                </h2>
                <p className="text-gray-600 mb-4 line-clamp-2">
                  {work.description}
                </p>
                
                <div className="flex justify-between items-center text-sm">
                  <span className="text-blue-600 font-medium">
                  Tafsilotlarni ko'rish
                  </span>
                  <span className="text-gray-500">
                    {new Date(work.date).toLocaleDateString('tr-TR')}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {filteredWorks.length === 0 && !error && (
          <div className="text-center text-gray-500 py-12 bg-white rounded-lg shadow">
            <svg className="w-16 h-16 text-gray-400 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
            </svg>
            <p>Ushbu turkumda hali hech qanday tadqiqotlar mavjud emas.</p>
          </div>
        )}
      </div>
    </div>
  );
}
  