import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Articles from "./pages/Articles";
import CompletedWorks from "./pages/CompletedWorks";
import QA from "./pages/QA";
import Testimonials from "./pages/Testimonials";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import { Suspense } from 'react';
import LoadingSpinner from './components/LoadingSpinner';
import ArticleDetail from './pages/ArticleDetail';
import CompletedWorkDetail from './pages/CompletedWorkDetail';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <Suspense fallback={<LoadingSpinner />}>
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/articles" element={<Articles />} />
                <Route path="/completed-works" element={<CompletedWorks />} />
                <Route path="/qa" element={<QA />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/articles/:id" element={<ArticleDetail />} />
                <Route path="/completed-works/:id" element={<CompletedWorkDetail />} />
              </Routes>
            </main>
          </Suspense>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
